  // 以下為進場動畫
  // var percent=0;
  // var timer=setInterval(function(){
  //   $(".bar").css("width",percent+"%")
  //   percent+=1
  //   if(percent>100){
  //     clearInterval(timer)
  //     $(".pageloading").addClass("compelet")
  //   }
  // },30);

function resize() {
  var winHeight = $(window).height();
  $(".modal-autoheight .modal-body").css({
    width: "auto",
    height: (winHeight - 200) + "px"
  });
}

function btnScroll($name){
  $($name).on('click',function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var $target = $(this.hash);
      $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
      if ($target.length) {
        var targetOffset = $target.offset().top - 75;
        $('html,body').animate({ scrollTop: targetOffset }, 1000);
        return false;
      }
    }
  });
}

// 以下為點擊後滾動至該區塊 : '.scrollLink'  
function scrollLink($name){
  $($name).on('click', function(e) {
      e.preventDefault();
      var target = $(this).attr("href");
      $('html, body').stop().animate({ scrollTop: $(target).offset().top}, 1000, function() {
        location.hash = target + 300;
      });
      
      return false;
  });
}

// 以下為主連結在滾動後的effect
function startScroll(){
  var header = $(".start-style");
  $(window).on('scroll',function() {    
    var scroll = $(window).scrollTop();
    if (scroll >= 10) {
      header.removeClass('start-style').addClass("scroll-on");
    } else {
      header.removeClass("scroll-on").addClass('start-style');
    }
  });
}

$(function() {
  //以下為Menu On Hover
	$('body').on('mouseenter mouseleave','.nav-item',function(e){
      if ($(window).width() > 750) {
        var _d=$(e.target).closest('.nav-item');_d.addClass('show');
        setTimeout(function(){
          _d[_d.is(':hover')?'addClass':'removeClass']('show');
        },1);
      }
  });

  // 以下為 Modal 相關設定
  if ($(".modal-autoheight").length > 0) {
    $(".modal").on("show.bs.modal", resize);
    $(window).on("resize", resize);
  }
  $('.modal').on('show.bs.modal', function(){
    var margin_vertical = parseInt( $(this).find('.modal-dialog').css('margin-top') ) + parseInt( $(this).find('.modal-dialog').css('margin-bottom') ) || 0;
    var height_header   = parseInt( $(this).find('.modal-header').css('height') ) || 0;
    var height_footer   = parseInt( $(this).find('.modal-footer').css('height') ) || 0;
    var height_body     = ( window.innerHeight - height_header - height_footer - margin_vertical - 10 ) + 'px';
    $(this).find('.modal-body').css('max-height', height_body).css('overflow', 'auto');

    $('.cookies').css('z-index','1');
  });
  
  // 以下為Gotop
  $(".float-link .link-btn.top").on('click',function () {
    $("html,body").animate({ "scrollTop": "0" })
  });
  $(window).on("load resize scroll", function (e) {
    var scroll = $(this).scrollTop();
    var wdh = $(window).height();
    if (scroll > 50) {
        $(".float-link").addClass("active");
    } else {
        $(".float-link").removeClass("active");
    }
  });

  // 以下為Swiper設定
  var parallaxSliderOptions = {
    speed: 1000,
    effect: 'coverflow',
    parallax: true,
    loop: true,
    grabCursor: true,
    centeredSlides: true,
    coverflowEffect: {
      rotate: 35,
      depth: 10,
      stretch: 0,
      modifier: 1,
      slideShadows: true
    },

    on: {
      init: function() {
        let swiper = this;
        for (let i = 0; i < swiper.slides.length; i++) {
          $(swiper.slides[i])
            .find('.img-container')
            .attr({
              'data-swiper-parallax': 0.35 * swiper.width,
              'data-swiper-paralalx-opacity': 0.5
            });
        }
        let index = swiper.activeIndex;
        $(swiper.slides).removeClass('active');
        $(swiper.slides[index]).addClass('active');
      },
      transitionEnd: function() {
        let swiper = this;
        let index = swiper.activeIndex;
        $(swiper.slides).removeClass('active');
        $(swiper.slides[index]).addClass('active');
      },
      resize: function() {
        this.update();
      }
    },

    pagination: {
      el: ".image-slider .swiper-pagination",
      clickable: true,
    },
  };
  var slider = new Swiper('.image-slider', parallaxSliderOptions);

  var swiperCaseImg = new Swiper('.index-historyCase .swiperCase-img', {
    watchSlidesProgress: true,
	  speed: 2000,
    autoplay:true,
  });
  
  var swiperCaseTxt = new Swiper(".index-historyCase .swiperCase-txt", {
	  speed: 2000,
    autoplay:true,
    navigation: {
      nextEl: '.index-historyCase .swiper-button-next',
      prevEl: '.index-historyCase .swiper-button-prev',
    },
    pagination: {
      el: ".index-historyCase .swiper-pagination",
    },
    thumbs: {
      swiper: swiperCaseImg,
    },
  });

  var swiper = new Swiper(".historySwiper", {
    speed: 1000,
    autoHeight: true, //高度随内容变化
    pagination: {
      el: ".historySwiper .swiper-pagination",
      clickable :true,
    },
  });

  // 以下為call Fun
  startScroll();
  btnScroll('.btnScroll');
  scrollLink('.scrollLink');

  $('.marquee').marquee({
    duration: 5000
  });

  if ($(document).width() < 1080) {
    $('.marquee').marquee({
      duration: 7500,
      pauseOnHover: true
    });
  }

});
